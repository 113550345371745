<template>
  <div class="login hp-100">
    <div class="login_container">
      <div class="login_img">
        <img src="../../../assets/img/backgroundImg.png" alt="" />
      </div>
      <div class="container">
        <el-form :model="loginInfo" :rules="rules">
          <!-- <img src="../../../assets/img/EliteGo.png" /> -->
          <!-- EliteGo -->
          <h1 style="font-size: 35px">数智产业基础数据管理系统</h1>
          <el-form-item prop="phone">
            <el-input
              placeholder="请输入手机号"
              v-model="loginInfo.phone"
              style="width: 332px"
              onkeyup="value=value.replace(/[^\d]/g,'')"
              maxlength="11"
            >
              <i slot="prefix" class="jr-iconjr-icon-phone" />
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              :type="pwdType"
              placeholder="请输入密码"
              v-model="loginInfo.password"
              style="width: 332px"
            >
              <i slot="prefix" class="el-icon-lock" />
              <div slot="suffix" :class="eyeType" @click="changeType" />
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              placeholder="请输入验证码"
              v-model="loginInfo.code"
              style="width: 332px"
            >
              <i slot="prefix" class="jr-iconjr-icon-ke" />
              <div slot="suffix" @click="remindClick" class="remind">
                {{ remind }}
                <span v-if="computeTime">{{ computeTime }}秒后重发</span>
              </div>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit" style="width: 332px"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from "@/api/global";
import { getSms } from "@/api/common.js";
export default {
  name: "login",

  data() {
    let checkPhone = (rules, value, callback) => {
      let reg = /^1[345789]\d{9}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入11位手机号"));
      } else {
        callback();
      }
    };
    return {
      loginInfo: {
        phone: "", // 账号
        password: "", // 密码
        code: "",
      },
      rules: {
        phone: [
          {
            type: "number",
            validator: checkPhone,
            message: "请输入11位有效手机号号码",
            trigger: ["blur"],
          },
        ],
      },
      remind: "获取验证码",
      computeTime: "",
      pwdType: "password",
      eyeType: "jr-iconjr-icon-eyes",
    };
  },
  methods: {
    async onSubmit() {
      const res = await login(this.loginInfo);
      if (res.code == 200) {
        sessionStorage.setItem("token", res.data.access_token);
        this.$router.replace({
          path: "/",
        });
        this.$store.commit("getMenuList", res.data.children);
        sessionStorage.setItem(
          "getMenuList",
          JSON.stringify(res.data.children)
        );
      } else if (res.code == 500) {
        let resData = res.msg;
        this.$message.error(resData);
      }
    },
    changeType() {
      this.pwdType = this.pwdType === "password" ? "text" : "password";
      this.eyeType =
        this.pwdType === "password"
          ? "jr-iconjr-icon-eyes"
          : "jr-iconjr-icon-eye";
    },

    async remindClick() {
      if (!this.computeTime) {
        const res = await getSms({ phoneNum: this.loginInfo.phone });
        if (res.code == 200) {
          this.$message.success("验证码发送成功");
        } else {
          this.$message.error(res.msg);
        }
      } else {
        this.$message.error("请稍后重试");
      }
      this.remind = "";
      let intervalId;
      // 如果当前没有计时
      if (!this.computeTime) {
        // 启动倒计时
        this.computeTime = 60;
        intervalId = setInterval(() => {
          this.computeTime--;

          if (this.computeTime <= 0) {
            this.remind = "重新发送";
            // 停止计时
            clearInterval(intervalId);
          }
        }, 1000);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  position: relative;
  height: 100%;
  // background: url("../../../assets/img/1.png") no-repeat 100% 100%;
  .login_container {
    display: flex;
    align-items: center;
    height: 100%;
  }
  &_container {
    h1 {
      font-size: 40px;
      color: #00a1e9;
      font-weight: 700 !important;
      font-weight: 500;
      margin-bottom: 40px;
    }
    .dateHub {
      background-color: #036eb7;
      font-size: 18px;
      color: #fff;
      padding: 17px 0 17px 24px;
      width: 220px;
      clip-path: polygon(75% 0%, 100% 100%, 0% 100%, 0% 0%);
    }
    .el-form {
      background-color: #fff;
      clip-path: polygon(100% 0%, 100% 78%, 90% 100%, 0% 100%, 0% 0%);
      border-radius: 0 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 30px 30px;
      img {
        width: 110px;
        height: 110px;
        height: 100%;
        padding: 20px 0;
      }
      .remind {
        color: #009fe8;
      }
      .remind:hover {
        cursor: pointer;
      }
      .el-button {
        padding: 12px 20px;
      }
    }
  }
}
.login_img {
  // font-size: 0px;
  height: 100%;
  // background-color: yellow;
}
img {
  height: 100%;
  // font-size:0px;
}
.container {
  margin: 0 auto;
}
</style>
